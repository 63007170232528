




import ProcedureBundlesCC from '/src/class-components/procedures/ProcedureBundlesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ProcedureBundlesCC
  }
})
export default class ProcedureBundles extends Vue {}
